import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import lazyImport from "./lazyImport";
import LayoutComponent from "./components/LayoutComponent";

import "./App.css";

const Home = lazyImport("./screens/Home");
const Services = lazyImport("./screens/Services");

const AutonomousDrones = lazyImport("./screens/Services/AutonomousDrones");
const Empowerment = lazyImport("./screens/Services/Empowerment");
const LawEnforcement = lazyImport("./screens/Services/LawEnforcement");
const SearchDetection = lazyImport("./screens/Services/SearchDetection");
const TacticalSystem = lazyImport("./screens/Services/TacticalSystem");
const TrafficSafetySystem = lazyImport("./screens/Services/TrafficSafetySystem");

const Blogs = lazyImport("./screens/Blogs");
const RiskAssessmentBlog = lazyImport("./screens/Blogs/RiskAssessmentBlog");
const PhysicalSecurityBlog = lazyImport("./screens/Blogs/PhysicalSecurityBlog");
const PlanningBlog = lazyImport("./screens/Blogs/PlanningBlog");

const About = lazyImport("./screens/About");
const Clients = lazyImport("./screens/Clients");
const Contact = lazyImport("./screens/Contact");
const ServiceDetail = lazyImport("./screens/ServiceDetail");

function App() {

  const LoadingMessage = () => (
    <div className="loader-container">
      <div className="loader" />
    </div>
  );
  return (
    <Router>
      <LayoutComponent>
        <Suspense fallback={<LoadingMessage />}>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route exact path="/services" element={<Services />} />

            <Route exact path="/services/autonomous-drones/" element={<AutonomousDrones />} />
            <Route exact path="/services/women-empowerment/" element={<Empowerment />} />
            <Route exact path="/services/law-enforcement-system/" element={<LawEnforcement />} />
            <Route exact path="/services/search-and-frisking-detection/" element={<SearchDetection />} />
            <Route exact path="/services/tactical-rescue-and-breaching-system//" element={<TacticalSystem />} />
            <Route exact path="/services/traffic-safety-systems//" element={<TrafficSafetySystem />} />

            <Route exact path="/services/:serviceId/detail" element={<ServiceDetail />} />

            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/blogs/risk-assessment/" element={<RiskAssessmentBlog />} />
            <Route exact path="/blogs/physical-security/" element={<PhysicalSecurityBlog />} />
            <Route exact path="/blogs/planning/" element={<PlanningBlog />} />

            <Route path="/about" element={<About />} />

            <Route path="/clients" element={<Clients />} />

            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Suspense>
      </LayoutComponent>
    </Router>
  );
}

export default App;
